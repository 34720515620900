import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppRoutes } from '@core/enums/routes.enum';
import { EntityConfig, getUserFriendlyName } from '@core/models/entity-config.model';

@Injectable({
  providedIn: 'root',
})
export class PreviousRouteService {
  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.currentUrl = decodeURI(this.router.url);

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = decodeURI(event.url);

        this.setLastAccessedRoute(event.url);
      }
    });
  }

  public getPreviousUrl(): string {
    return this.previousUrl === this.currentUrl ? null : this.previousUrl;
  }

  public getDefaultBackUrl(typeOfPage: string, entityConfig: EntityConfig): string | string[] {
    const returnUrl = this.activatedRoute.snapshot.queryParamMap?.get('returnUrl');

    if (returnUrl) {
      return returnUrl;
    }

    switch (typeOfPage) {
      case AppRoutes.overview:
      case AppRoutes.detail:
      case AppRoutes.add:
        return ['/', AppRoutes.admin, getUserFriendlyName(entityConfig)];
      case AppRoutes.edit:
      case AppRoutes.copy:
        return ['/', AppRoutes.admin, getUserFriendlyName(entityConfig), entityConfig.id];
      case AppRoutes.manual:
      default:
        return ['../'];
    }
  }

  public setLastAccessedRoute(route: string): void {
    let lastRoute = route;

    if (lastRoute?.includes('?redirectUrl=')) {
      // only take paths that are after auth/login to avoid excluded routes
      const dividedUrl = lastRoute.split('?redirectUrl=')[1].split('%2F');

      lastRoute = dividedUrl
        .reduce((previousValue, currentValue) => {
          return previousValue + '/' + currentValue;
        }, '')
        .slice(2);
    }

    const excludedRoutes = ['auth', 'more-actions', 'public'];
    const isExcludedRoute = excludedRoutes.some((excludedRoute) => {
      return lastRoute.includes(excludedRoute);
    });
    const isValidRoute = !lastRoute.includes('undefined');

    if (!isExcludedRoute && isValidRoute) {
      localStorage.setItem('lastRoute', lastRoute);
    }
  }

  public getLastAccessedRoute(): string {
    return localStorage.getItem('lastRoute');
  }
}
